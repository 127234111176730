<template>
  <div class="accordion">
    <div class="container-header">
      <h3>Solicitação de tratamento</h3>
      <div
        class="chevron"
        @click="handleOpen"
        :class="{ rotateUp: !open, rotateDown: open }"
      >
        <ChevronUp />
      </div>
    </div>

    <div
      v-for="(item, key) in items"
      :key="key"
      class="mt-3"
      :class="{ openTable: open, closeTable: !open, 'd-none': !open }"
    >
      <p>
        <span class="header-text treatment">{{ item?.tratamento }}</span>
        |
        <span class="header-text legend">
          Tratamento para
          {{ item?.tratamentoPara?.label ?? item?.tratamentoPara }}
        </span>
        <img
          v-if="item?.tratamentoPara?.value"
          src="@/assets/images/glauco_symbol.png"
          class="logo-glauco"
        />
      </p>
      <b-table-simple class="table-container" borderless>
        <b-thead>
          <b-tr>
            <b-th
              v-for="(header, key) in getHeaders(item?.tratamento)"
              :key="key"
            >
              {{ header.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(value, index) in item.sessoes" :key="index">
            <b-td class="text-center">{{ value.sessao }}</b-td>
            <b-td v-if="item?.tratamento === 'Laserterapia'">
              {{ value.tipo?.label ?? value.tipo }}
            </b-td>
            <b-td v-if="item?.tratamento === 'Laserterapia'">
              {{ value.procedimento?.label }}
            </b-td>
            <b-td v-if="item?.tratamento !== 'Laserterapia'">
              {{ value.via }}
            </b-td>
            <b-td v-if="item?.tratamento !== 'Laserterapia'">
              {{ value.farmaco?.label }}
            </b-td>
            <b-td>{{ parseLaterality(value.lateralidade) }}</b-td>
            <b-td>{{ value.intervalo }}</b-td>
            <b-td>{{ value.situacao }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div v-if="item?.outrasInformacoes?.length" class="mt-4">
        <span class="header-text legend">Outras informações</span>
        <b-table-simple class="table-container" borderless>
          <b-thead>
            <b-tr>
              <b-th>Nome</b-th>
              <b-th>Informação</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, key) in item.outrasInformacoes" :key="key">
              <b-td>{{ item?.name }}</b-td>
              <b-td>{{ item?.template }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'
import { parseLaterality } from '@/utils/eyesHelper'

export default {
  components: {
    ChevronUp
  },
  props: {
    headers: Array,
    type: String,
    items: Array
  },
  data: () => ({
    open: true
  }),
  methods: {
    parseLaterality,
    handleOpen() {
      this.open = !this.open
    },
    getHeaders(treatment) {
      return treatment === 'Laserterapia'
        ? [
            { label: 'Sessão', key: 'sessao' },
            { label: 'Tipo', key: 'tipo' },
            { label: 'Procedimento', key: 'procedimento' },
            { label: 'Lateral.', key: 'lateralidade' },
            { label: 'Interval.', key: 'intervalo' },
            { label: 'Situação', key: 'situacao' }
          ]
        : [
            { label: 'Dose', key: 'sessao' },
            { label: 'Via', key: 'via' },
            { label: 'Fármaco', key: 'farmaco' },
            { label: 'Lateral.', key: 'lateralidade' },
            { label: 'Interval.', key: 'intervalo' },
            { label: 'Situação', key: 'situacao' }
          ]
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  margin: 20px 0;

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;

    .files-button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-500);
    }

    h3 {
      font-size: 18px;
      color: var(--dark-blue);
      font-weight: 600;
    }

    .chevron {
      width: 25px;
      height: 25px;

      > svg {
        cursor: pointer;
        stroke: var(--neutral-600);
        transition: 0.5s;
      }
    }

    .rotateUp {
      animation: rotateUp 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .rotateDown {
      animation: rotateDown 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    table-layout: fixed;

    thead {
      border-bottom: 1px solid var(--neutral-300) !important;
    }

    thead,
    tbody {
      width: 100%;
      margin-top: 10px;
      > th,
      tr {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: left;
        height: 100%;
        width: 100%;

        th {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: left;
          width: 100%;
        }

        td {
          height: 75px;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: left;
          width: 100%;
          overflow: auto;
          transition: 0.3s;
          flex-wrap: wrap;
        }
      }

      th td {
        font-size: 14px !important;
        font-weight: 700 !important;
      }

      tr td {
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px dotted var(--neutral-300);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--neutral-000);
    border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 100px;
    border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--neutral-200);
  }

  .header-text {
    font-weight: 600;
    font-size: 14px;
    color: var(--type-active);

    &.treatment {
      color: var(--type-active);
    }

    &.legend {
      color: var(--type-placeholder);
    }
  }
}
</style>
